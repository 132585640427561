import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { Field as FormikField } from 'formik'
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import RadioGroup from '@system/radio-group'

const useStyles = makeStyles((theme) => ({
  customSelect: {
    padding: '8px 0 8px 0',
    '& label': {
      color: theme.palette.background.slate,
      fontSize: '20px',
      transform: 'translate(13px, 20px)',
      zIndex: 1,
    },
    '& svg': {
      fontSize: '1.5rem',
      color: theme.palette.background.slate,
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
      },
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& .MuiFormLabel-filled': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  selectInput: {
    height: '44px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '0',
    fontSize: '20px',
    '& > div': {
      fontSize: '20px',
      paddingTop: '4px',
      paddingBottom: '4px',
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  fieldLabel: {
    marginTop: '1rem',
    fontSize: '.75rem',
    color: theme.palette.common.black,
  },
  errorMessage: {
    color: 'red',
  },
}))

const validate = (value, isRequired) => {
  let error
  if (!value && isRequired) {
    error = 'Field Required'
  }
  return error
}

const CustomSelect = (props) => {
  const {
    field,
    fieldId,
    form: { touched, errors },
    options,
    placeholder,
    required,
    displayAsRadioButtons,
  } = props
  const classes = useStyles(props)
  const error = touched[field.name] && errors[field.name] && required
  field.value = field.value || ''
  const [isOpen, setIsOpen] = useState(false)

  const toggleStatus = (status) => {
    setIsOpen(status)
  }

  return (
    <>
      <FormControl key={props.index} className={classes.customSelect}>
        {displayAsRadioButtons ? (
          <RadioGroup {...props} />
        ) : (
          <>
            {!field.value && (
              <InputLabel htmlFor={fieldId}>{placeholder}</InputLabel>
            )}
            <Select
              {...props}
              {...field}
              className={classes.selectInput}
              variant="outlined"
              required={required}
              name={fieldId}
              open={isOpen}
              error={error}
              onOpen={() => toggleStatus(true)}
              onClose={() => toggleStatus(false)}
              placeholder={placeholder}
            >
              {!!options &&
                options.map((option) => (
                  <MenuItem key={option._uid} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </>
        )}
      </FormControl>
      {error && (
        <Box className={classes.errorMessage}>{errors[field.name]}</Box>
      )}
    </>
  )
}

const MarketoFormSelect = (props) => {
  const {
    fieldId,
    required,
    fieldOptions,
    fieldName,
    placeholder,
    displayAsRadioButtons,
    _uid,
  } = props.blok
  const classes = useStyles()

  return (
    <SbEditable content={props.blok}>
      <InputLabel
        htmlFor={fieldId.trim() || 'Select'}
        className={classes.fieldLabel}
      >
        {fieldName}
      </InputLabel>
      <FormikField
        {...props.blok}
        name={fieldId.trim() || 'Select'}
        index={fieldId.trim() || _uid}
        options={
          fieldOptions && fieldOptions.length > 0
            ? fieldOptions[0].fieldOptions
            : fieldOptions
        }
        required={required}
        component={CustomSelect}
        displayAsRadioButtons={displayAsRadioButtons}
        placeholder={placeholder || 'Please select an option'}
        validate={(e) => validate(e, required)}
        fieldName={fieldName}
      />
    </SbEditable>
  )
}

export default MarketoFormSelect
